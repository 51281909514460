/* eslint-disable @typescript-eslint/no-var-requires */
import React from 'react'
import './src/styles/global.css'

export function wrapPageElement({ element, props }) {
  const Layout = element.type.Layout ?? React.Fragment
  return (
    <Layout className='scroll-smooth' {...props}>
      {element}
    </Layout>
  )
}
